<template>
    <div class="order-messages">
        <div class="card shadow-sm">
            <div class="card-body px-2 py-2 border-bottom">
                <div class="py-1">
                    <div class="d-flex">
                        <form class="search-bar flex-grow-1 m-0">
                            <div class="position-relative">
                                <input v-model="searchQuery" type="text" style="padding-left:20px;" class="rounded-0 form-control form-control-white" placeholder="Search for messages..." />
                            </div>
                        </form>
                        <b-dropdown right variant="light">
                            <template #button-content>
                                <i class="mdi mdi-dots-vertical"></i>
                            </template>
                            <b-dropdown-item @click="status.modal = true" href="#">Update Status</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>
            </div>
            <div class="card-body p-2 ">
                <message-window ref="orderLogPSWindow" :messages="messageData">
                    <template v-slot:footer>
                        <div class="row">
                            <div class="col">
                                <div class="mt-2 p-0 rounded">
                                    <form class="needs-validation" @submit.prevent="sendMessage" name="chat-form" id="chat-form">
                                        <b-input-group>
                                            <b-input-group-append>
                                                <button @click="showUploadFileModal = true" type="button" class="btn btn-light"><i class="fas fa-paperclip"></i></button>
                                            </b-input-group-append>
                                            <b-form-input v-model="form.message" placeholder="Enter your text"></b-form-input>
                                            <b-input-group-append>
                                                <button type="submit" class="btn btn-primary chat-send btn-block">
                                                        <i class="mdi mdi-send"></i>
                                                </button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </form>
                                </div>
                            </div>
                            <!-- end col-->
                        </div>
                    </template>
                </message-window>
            </div>
        </div>

        <div>
            <b-modal size="sm" centered v-model="status.modal" title="Select Status" header-close-variant="primary" title-class="font-18 text-primary" hide-footer>
                <b-form-group id="input-group-2">
                    <b-form-select
                        id="input-3"
                        v-model="status.model"
                        :options="status.list"
                        required
                    ></b-form-select>
                </b-form-group>
                <button @click="updateStatus()" type="button" class="btn btn-primary">Update</button>
            </b-modal>
            <b-modal centered v-model="showUploadFileModal" title="Upload Files" header-close-variant="primary" title-class="font-18 text-primary" hide-footer>
                <upload-attachment @scrollMessagesUp="resetForm(); scrollMessageAreaUp() "/>
            </b-modal>
        </div>
    </div>
</template>

<script>

import MessageWindow from "./components/MessageWindow.vue"
import UploadAttachment from "./components/UploadAttachment.vue"

export default {
	components: {
        MessageWindow,
        UploadAttachment
	},
	data() {
		return {
            showUploadFileModal:false,
            form:{
                message: ""
            },
            status:{
                modal: false,
                list: [
                    { text: 'Select One', value: null },
                    { text: 'Pending', value: 'pending' },
                    { text: 'Delivered', value: 'delivered' },
                    { text: 'Completed', value: 'completed' }
                ],
                model: null
            }
		}
	},
	computed: {
        order(){
            return this.$store.state.orderList.order
        },
        messageData(){
            return this.$store.getters['orderList/getMessages']

        },
        searchQuery: {
            get() {
                return this.$store.state.orderList.messageSearchQuery
            },
            set(val) {
                this.$store.commit('orderList/SET_MESSAGE_SEARCH_QUERY', val)
            }
        },
	},
	methods: {
        sendMessage() {
            if (!this.form.message) { this.alertError("Please type something"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.form))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/order-messages/${this.$route.params.orderId}/create`,formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.alertSuccess(response.data.success)
                    this.$store.commit(`orderList/ADD_MESSAGE`,response.data.message)
                    this.resetForm();
                    this.scrollMessageAreaUp();
                }else if(response.data.errors){
                    response.data.errors.forEach((error) => { this.alertError(error)})
                }else{
                    this.alertError(response.data.error)
                }
            }).catch((error)=> {console.log(error)  })
        },
        updateStatus() {
            if (!this.status.model) { this.alertError("You have to select one"); return;}
            const formData = new FormData()
            formData.append('data', JSON.stringify({orders: [this.order.id],status: this.status.model}))
             this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/orders/${this.order.id}/update-status`,formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.alertSuccess(response.data.success)
                    this.resetForm();
                    this.$store.dispatch("orderList/fetchOrder",this.order.id)
                }else if(response.data.errors){
                    response.data.errors.forEach((error) => { this.alertError(error)})
                }else{
                    this.alertError(response.data.error)
                }
            }).catch((error)=> {console.log(error)  })
        },
        scrollMessageAreaUp(){
            setTimeout(()=>{
                this.$refs.orderLogPSWindow.scrollMessageAreaUp()
            },1000)
        },
        resetForm(){
            this.showUploadFileModal = false;
            this.form.message =  "";
            this.status.modal = false;
        },
	},
    mounted(){
        this.scrollMessageAreaUp()
    }

}
</script>

