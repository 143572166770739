

<template>
  <div>
    <div class="">
      <h4 class="mb-3">Buyer filled requirements</h4>
      <div class="justify-center items-center">
        <div class="mb-4">
          <ul class="list list-unstyled">
            <template v-for="(requiredField, reIndex) in requirements">
              <li :key="reIndex"
                    class="list__item d-block">
                  <h6 class="d-flex mb-1"><span class="">✔️</span> {{requiredField.question}}</h6>
                  <div class="pl-2 pt-3" v-if="requiredField.key == 'logo_type'">
                    <img class="shadow" :src="`${$store.state.domain}/${logoType.path}`" :alt="logoType.name" width="100" /> 
                    <div class="color-type-name py-2">
                      <h6 class="mb-0">{{logoType.name}}</h6>
                    </div>
                  </div>
                  <div class="pl-2" v-else-if="requiredField.key == 'color_types'">
                    <div class="row color-types">
                      <div v-for="(colorType, clIndex) in colorTypes" :key="clIndex"
                        class="col-md-3 mb-3 mb-md-0 col-sm-6">
                          <div class="color-type shadow bg-white">
                              <div class="row no-gutters">
                                  <div v-for="(swatch, sIndex) in 9" :key="sIndex"
                                    :style="{'background-color': colorType.colors[sIndex]}"
                                    class="col-4 color-type-block">
                                  </div>
                              </div>
                              <div class="color-type-name py-2">
                                  <h6 class="mb-0 text-center">{{colorType.name}}</h6>
                              </div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="text-sm pl-2 pb-3" style="color:#aaa;">
                      {{requiredField.answer}}
                  </div>
              </li>
            </template>
          </ul>
        </div>
      </div>
    </div>
    <hr class=""/>
    <div class="row">
      <div class="col-12">
          <h4 class="font-weight-bold">Attached Files</h4>
      </div>
      <div class="col-sm-6 col-md-4 mb-4" v-for="(file, index) in files" :key="index">
        <div v-if="isImageFile(file.extension)" class="">
          <b-img thumbnail class="mr-3" fluid :src="`${$store.state.domain}/${file.path}`" :alt="file.name"></b-img>
        </div>
        <a :href="`${$store.state.domain}/download?file_path=${file.path}`"
            :download="file.name" style="color:inherit;" class="downloadable">
          <div>
            <h6 class="mb-0">{{file.name}}</h6>
            <span class="mr-2 "> size: {{file.size}}, ext.: {{file.extension}} </span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    requirements(){
      return this.order.cart.cart.requirements
    },
    logoType(){
      return this.order.cart.cart.logoType
    },
    colorTypes(){
      return this.order.cart.cart.colorTypes
    },
    files(){
      return this.order.sample_files
    },
    
  },
  methods:{
    isImageFile(ext){
      return (ext === 'jpeg' || ext === 'png' || ext === 'jpg' || ext === 'svg')
    },
  }
}
</script>

<style scoped>
.color-types .color-type-block{
    height: 45px;
}

@media(max-width:567px){
    .color-types .color-type-block{
        height: 40px;
    }
}
</style>


