<template>
  <div v-if="addons && addons.length" class="">
    <h4 class="mb-3">Extra Services</h4>
    <div class="justify-center items-center">
        <div class="mb-4">
            <ul class="list list-unstyled">
                <template v-for="(addon, adIndex) in addons">
                    <li :key="adIndex"
                    class="list-item d-block">
                        <h6 class="mb-0 font-weight-bold" >✔️ {{addon.name}} @ ${{addon.charge | money_format}}</h6>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</div>
</template>

<script>
export default {
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    addons(){
        return this.order.cart.cart.addons
    },
  }
}
</script>
