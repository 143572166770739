
<template>
  <div>
    <div class="row">
        <div class="col-12">
           <vue-dropzone id="dropzone" ref="fileUploadDrop" class="border-0" :use-custom-slot="true" :options="dropzoneOptions">
              <div class="dz-message my-0 needsclick">
                  <i class="h1 text-primary mdi mdi-plus"></i>
                  <h4>Upload Files or Drop files</h4>
                  <span class="text-primary font-13">
                      <em>maximum of 5 files</em>
                  </span>
              </div>
          </vue-dropzone>
        </div>
    </div>
    <div class="row justify-content-end">
        <div class="col-12">
          <div class="text-center">
            <button type="button" :disabled="!validateForm" @click.prevent="sendAttachment()" class="btn btn-primary mt-2">
                <i class="mdi mdi-content-save"></i> Upload
            </button>
        </div>
      </div>
    </div>
</div>
</template>

<script>

export default {
 name: "upload-attachment",
  data() {
    return {
      dropzoneOptions: {
        url: "http//null",
        thumbnailWidth: 550,
        maxFilesize:10,
        maxFiles:5,
        autoProcessQueue:false,
        acceptedFiles: "image/*,.ai,.eps,.psd,.zip,.pdf,application/zip",
        headers: {
            "My-Awesome-Header": "header value",
        },
        previewTemplate: this.dropzoneTemplate(),
      },
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any()
    },
  },
  methods: {
    sendAttachment() {
      this.$validator.validateAll().then(result => {
        if(!this.collectFiles().length){ this.alertError("Please a file is required"); return;}
        const formData = new FormData()
        this.collectFiles().forEach((item, index) => formData.append('file_'+index, item))
        this.requestProcessor(formData, `${this.$route.params.orderId}/send-files`)
      })
    },
    requestProcessor(formData, url, action='ADD'){
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/order-messages/${url}`,formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
                this.alertSuccess(response.data.success)
                this.$store.commit(`orderList/${action}_MESSAGE`,response.data.message)
                this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
                this.resetForm();
                this.scrollMessageAreaUp();
            }else if(response.data.errors){
                response.data.errors.forEach((error) => { this.alertError(error)})
            }else{
                this.alertError(response.data.error)
            }
        }).catch((error)=> {console.log(error)  })
    },
    scrollMessageAreaUp(){
      this.$emit('scrollMessagesUp');
    },
    collectFiles(){
      let arrafile = [];
      if(this.$refs.fileUploadDrop){
        arrafile = [...this.$refs.fileUploadDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    resetForm() {
      this.$refs.fileUploadDrop.removeAllFiles();
    },
  }
}

</script>

